import React from "react"
import { Helmet } from "react-helmet"
import LayoutPost from "../components/layout.post";
import Img from 'gatsby-image'

import { graphql } from 'gatsby'

import Bio from "../components/bio";


export default function Template({
  data, // this prop will be injected by the GraphQL query we'll write in a bit
}) {
  const { markdownRemark: post } = data // data.markdownRemark holds our post data


  return (

    <LayoutPost>

      <Helmet title={`${post.frontmatter.title} | miszczuk.org`} />
      
      <article className="uk-article" >
        <div className="uk-card uk-card-default uk-card-body uk-margin">
          <h1 className="uk-article-title">{post.frontmatter.title}</h1>

          <em className="uk-article-meta" >{post.frontmatter.date}</em>

          <Img className="uk-margin" sizes={post.frontmatter.featuredImage.childImageSharp.sizes} />

          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </article>

      <hr className="" />

      <Bio />

    </LayoutPost>
  )
}


export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        path
        date(formatString: "MMMM DD, YYYY")
        updated
        description
        category
        tags
        excerpt
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 1200) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`