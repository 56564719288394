import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'


const HeaderPost = ({ siteTitle }) => (
  <div className="uk-section uk-section-muted uk-padding-remove">
    <div className="uk-container uk-margin-top">
      <div className="uk-width-1-1 uk-width-3-4@m uk-align-center">

        <nav class="uk-navbar-container uk-navbar uk-visible@s uk-heading-line" uk-navbar>
          
          <div class="uk-navbar-left">
            <ul className="uk-navbar-nav">
              <li className="">
                <Link to="/" activeClassName="uk-active"><h2>miszczuk.org</h2></Link>
              </li>     
            </ul>
          </div>
          <div class="uk-navbar-right">
            <ul class="uk-navbar-nav">
              <li className="">
                <Link to="/omnie/" activeStyle={{ color: '#666666' }}><h2>o mnie</h2></Link>
              </li>
              <li className="">
                <a href="https://github.com/RafalMiszczuk" className="uk-icon-link" data-uk-icon="icon:github; ratio: 1.5" target="_new" > </a>
              </li>
              <li className="">
                <a href="https://twitter.com/RafalMiszczuk" className="uk-icon-link" data-uk-icon="icon:twitter; ratio: 1.5" target="_new" > </a>
              </li>
            </ul>

          </div>
        </nav>

        <nav className="uk-navbar uk-navbar-container uk-margin uk-hidden@s">
          <div className="uk-navbar-left">
            <button class="uk-button uk-button-text uk-margin-small-right" >miszczuk.org</button>
          </div>
          <div className="uk-navbar-right">
            <span data-uk-icon="menu" data-uk-toggle="target: #offcanvas-usage"></span>
          </div>
        </nav>

        <div id="offcanvas-usage" className="uk-offcanvas" data-uk-offcanvas="mode: push; container:false; flip:true">
          <div class="uk-offcanvas-bar uk-offcanvas-bar-flip uk-offcanvas-bar-show" >

            <ul className="uk-nav uk-nav-offcanvas uk-text-left">
              <li className="">
                <Link to="/">miszczuk.org</Link>
              </li>     
              <li className="">
                <Link to="/omnie/">o mnie</Link>
              </li>
              <li className="">
                <a href="https://github.com/RafalMiszczuk" target="_new">Github</a>
              </li>
              <li className="">
                <a href="https://twitter.com/RafalMiszczuk" target="_new">Twitter</a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
)

HeaderPost.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderPost.defaultProps = {
  siteTitle: ``,
}

export default HeaderPost
