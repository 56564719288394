import React from 'react'

import profilePic from '../images/me.jpg'

const Bio = () => (


      <div className="uk-flex-middle uk-grid uk-margin">
        <div className="uk-width-3-4@m">
          <p>Napisany przez Rafała Miszczuka, który uwielbia budować nowe rzeczy. Czasami nie bardzo przydatne, ale fajne rzeczy.</p>
        </div>
        <div className="uk-width-1-4@m uk-flex-first">
          <img
            src={profilePic}
            alt={`Rafał Miszczuk`}
            style={{
              marginRight: 30,
              marginTop: 20,
              marginBottom: 0,
              width: 80,
              height: 80,
            }}
            className="uk-border-circle"
          />
        </div>
      </div>


    )


export default Bio