import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import HeaderPost from './header.post'

import './layout.css';



const LayoutPost = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQueryPost {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}

    render={data => (
      <div className="">
        <HeaderPost siteTitle={data.site.siteMetadata.title} />

        <div className="uk-section uk-background-muted">
          <div className="uk-container">
            <div className="uk-width-1-1 uk-width-3-4@m uk-align-center ">

{children}


            </div>
          </div>
        </div>

      </div>
    )}
  />
)

LayoutPost.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutPost
